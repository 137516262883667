
import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';
import LoadingScreen from '../../components/Layout/LoadingScreen.vue';

@Component({
  components: {LoadingScreen}
})
export default class Success extends Vue {
  loading: boolean = true;

  created() {
    this.$setPageTitle('Статус платежа | DOTALOOT');
  }

  async mounted() {
    try {
      const response = await Axios.get('/api/payment/last-user-payment');

      const {payment} = response.data;

      if (!payment) return this.$router.push('/');

      if (payment.is_paid) {
        this.$notify({
          group: 'notification',
          duration: 10000,
          type: 'success',
          title: 'Пополнение баланса',
          text: `Пополнение прошло успешно, вы получили <span class="_accent">${payment.to_balance} монет</span> на баланс!`
        });
      } else {
        this.$notify({
          group: 'notification',
          duration: 10000,
          type: 'warning',
          title: 'Пополнение баланса',
          text: `Возможно Ваше пополнение ещё не было обработано, это может занять какое-то время.`
        });
      }

      setTimeout(() => {
        this.$router.push('/');
      }, 1000);
    } catch (e) {
      console.log(e);
      this.$router.push('/');
    }
  }
}
